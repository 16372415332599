<template>
    <div>
        <b-row class="mb-5">
            <h6></h6>
        </b-row>
        <b-row>
            <b-tabs
                content-class="mt-3"
                active-nav-item-class="active-nav-item"
                active-tab-class="active-tab"
                lazy
                class="w-100"
                v-model="activeTab"
            >
                <b-tab title="Użytkownicy">
                    <UserTab :users_roles="users_roles" :refresh="refresh"></UserTab>
                </b-tab>

                <b-tab title="Role systemowe">
                  <RoleTab :permissions="roles_permissions" :applications="applications" :refresh="refresh"></RoleTab>
                </b-tab>

                <b-tab title="Uprawnienia">
                    <PermissionTab :applications="applications" :refresh="refresh"></PermissionTab>
                </b-tab>

                <template #tabs-end>
                    <li role="presentation" class="nav-item align-self-center ml-auto">
                        <b-button v-if="activeTab === 1" size="sm" class="bi bi-plus-lg btn-blue" @click="addRoleItem()"> Dodaj nową rolę</b-button>
                        <b-button v-if="activeTab === 2" size="sm" class="bi bi-plus-lg btn-blue" @click="addPermissionItem()"> Dodaj nowe uprawnienie</b-button>
                    </li>
                </template>
            </b-tabs>
        </b-row>
        <UserModal
            :roles="users_roles"
            @dataChanged="refreshData"
            :modal="modal"
            :endpoint="endpoint"
        />
    </div>
</template>

<script>
import UserModal from "./modals/UserModal.vue";
import UserTab from "./tabs/UserTab.vue"
import PermissionTab from "./tabs/PermissionTab.vue"
import RoleTab from "./tabs/RoleTab.vue"
import RoleModal from "./modals/RoleModal.vue";
export default {
    props: {
        users: {type: Object, default: null},
        users_roles: {type: Array},
        applications: {type: Object},
        roles_permissions: {type: Object|Array},
    },
    components:{
        RoleModal,
        UserModal, UserTab, RoleTab, PermissionTab
    },
    data(){
        return {
            activeTab: 0,
            itemData: {},
            endpoint: 'api.admin.user.',
            refresh: false,
            permissionsTabId: 2,
            modal: 'modal-user-add',
            modalRole: 'modal-role',
            modalPermission: 'modal-permission'
        }
    },
    methods: {
        refreshData(){
            this.refresh = !this.refresh;
        },
        addRoleItem(){
            this.itemData = {};
            this.$bvModal.show(this.modalRole);
        },
        addPermissionItem(){
            this.itemData = {};
            this.$bvModal.show(this.modalPermission);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>


