<template>
    <div :id="id" size="lg" class="mb-2">
        <b-form-select
            v-model="selected"
            @change="itemSelect"
            :disabled="disabled"
            :options="availableItems"
            :textField="textField"
            :valueField="valueField"
            class="mb-2"
        >
            <template #first>
                <b-form-select-option disabled :value="null">{{ placeholder }}</b-form-select-option>
            </template>
        </b-form-select>
        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2 w-100">
            <li>
                <div v-for="tag in tags">
                    <b-form-checkbox
                        v-model="tag[valueField]"
                        :title="tag[textField]"
                        :key="tag[valueField]"
                        :value="tag[valueField]"
                        @input="removeTag(tag)"
                        checked="checked"
                    >
                        {{tag[textField]}}
                    </b-form-checkbox>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        id: {type: String, default: 'role-select'},
        placeholder: {type: String, default: ''},
        items: [],
        selectedItems: []
    },
    data() {
        return {
            selected: null,
            tags: this.selectedItems ?? [],
            textField: 'name',
            valueField: 'id',
        }
    },
    methods: {
        itemSelect(id) {
            const item = this.availableItems.find(i => i.id === id);
            let role = {
                id: item.id,
                name: item.name,
                departments: []
            };

            if (item) {
                this.tags.push(role);
            }
            this.$emit('itemAdd', role);
        },
        removeTag(tag) {
            this.tags = this.tags.filter(t => t.id !== tag.id);
            this.clearSelected();
            this.$emit('itemRemove', tag);
        },
        clearSelected() {
            this.selected = null
        },

    },
    computed: {
        availableItems() {
            return this.items.filter(available => !this.tags.some(tag => available.id === tag.id));
        },
        disabled() {
            return this.availableItems.length === 0;
        }
    },
    watch: {
        items() {
            this.tags = [];
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
