<template>
    <div class="w-100">
        <b-row class="ml-0 mt-4 mb-4 mr-0">
            <b-col cols="6">
                <b-row v-if="withApplyClearFilters">
                    <slot name="data">
                    </slot>
                    <b-col class="pr-1 pl-1">
                        <slot name="buttons"></slot>
                        <b-button class="bi bi-funnel-fill blue" variant="transparent" @click="$emit('applayFilter')"></b-button>
                        <b-button class="bi bi-x-lg blue" variant="transparent" @click="$emit('clearFilter')"></b-button>
                    </b-col>

                </b-row>
            </b-col>
            <b-col>

            </b-col>
            <b-col cols="3">
                <b-row>
                    <b-input-group v-if="withSearch"
                    >
                        <slot name="search"></slot>
                        <b-input-group-append is-text>
                            <span class="bi bi-search"></span>
                        </b-input-group-append>
                    </b-input-group>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props:{
        withSearch: {type: Boolean, default: false},
        withApplyClearFilters: {type: Boolean, default: true}
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    }
}
</script>
<style>
.input-group-text{
    background-color: transparent;
    border-left: none;
}
.blue{
    color: #002F6D;
}
</style>
