<template>
    <div>
        <b-modal id="modal-role" hide-footer title="Rola" size="xl">
            <b-form @submit="onSubmit">
                <b-form-group
                    id="input-group-id"
                    label="Id"
                    label-for="id"
                    v-if="role.id"
                >
                    <b-form-input
                        id="id"
                        v-model="role.id"
                        type="number"
                        disabled
                    ></b-form-input>

                </b-form-group>
                <b-form-group id="input-group-name"
                              label="Nazwa"
                              label-for="name">
                    <b-form-input
                        id="name"
                        v-model="role.name"
                        type="text"
                        placeholder="Podaj nazwę"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-key"
                              label="Klucz"
                              label-for="key">
                    <b-form-input
                        id="key"
                        v-model="role.key"
                        type="text"
                        placeholder="Podaj klucz"
                        pattern="[a-zA-Z0-9_]+"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-description"
                              label="Opis"
                              label-for="description">
                    <b-form-input
                        id="description"
                        v-model="role.description"
                        type="text"
                        placeholder="Podaj opis"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-module"
                              label="Moduł"
                              label-for="module">
                    <application-form-select
                        :selectedItem="role.application_slug"
                        @applicationChange="applicationChange"
                        :applications="applications"
                    />
                </b-form-group>
                <b-form-group label="Uprawnienia" label-for="tags-component-select">
                    <tag-select
                        :id="'tags-component-select'"
                        :placeholder="'Wybierz uprawnienie'"
                        :items="applicationPermissions"
                        @itemChange="setPermissions"
                        :selected-items="selectedPermissions"
                        :textField="'full_name'"
                    >
                    </tag-select>
                </b-form-group>

                <div class="modal-footer">
                    <b-button id="cancel" class="" @click="close">Anuluj</b-button>
                    <b-button type="submit" variant="danger">Zapisz</b-button>
                </div>

            </b-form>

        </b-modal>
    </div>

</template>
<script>
export default {
    name: "RoleModal",
    props: {
        itemData: {},
        endpoint: {type: String, default: ''},
        modal: {type: String, default: ''},
        applications: {type: Object, default: {}},
        permissions: {type: Object|Array, default: {}}
    },
    data() {
        return {
            role: this.itemData,
            selectedPermissions: [],
            applicationPermissions: [],
            textField: 'name',
            valueField: 'id',
        }
    },
    methods: {
        close() {
            this.clearData();
            this.$bvModal.hide(this.modal)
        },
        onSubmit(event) {
            event.preventDefault();
            this.onModalSubmit(this.newItem, this.endpoint, {role: this.role.id}, this.payload);
        },
        applicationChange(event) {
            this.role.application = event;
            this.clearSelectedPermissions();
            this.setApplicationPermissions(event);
        },
        clearSelectedPermissions() {
            this.selectedPermissions = []
        },
        clearData() {
            this.clearSelectedPermissions();
            this.applicationPermissions = [];
            this.role = {};
        },
        setApplicationPermissions(application) {
            this.applicationPermissions = this.permissions.filter(permission => permission.application == application);
        },
        setPermissions(permissions) {
            this.selectedPermissions = Object.assign([], permissions);
        }
    },
    computed: {
        newItem() {
            return !!!this.itemData.id;
        },
        payload() {
            return {
                name: this.role.name ?? null,
                key: this.role.key ?? null,
                description: this.role.description ?? null,
                application: this.role.application ?? null,
                permissions: this.selectedPermissions.map(p => p.id) ?? []
            }
        },
    },
    watch: {
        itemData: function (newValue, oldValue) {
            if (!this.isObjectEmpty(this.itemData)) {
                this.role = this.itemData;
                this.role.application = this.role.application_slug;
                this.setApplicationPermissions(this.role.application_slug);
                this.clearSelectedPermissions();
                this.setPermissions(this.role.permissions);
            }
        }
    }
}
</script>
<style lang="">

</style>
