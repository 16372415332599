<template>
    <div>
        <table-grid-filter @applayFilter="refreshData()" @clearFilter="clearFilter()" :with-search="true">
            <template #data>
                <b-row>
                    <b-col>
                        <application-form-select
                            :applications="applications"
                            @applicationChange="applicationChange"
                            :clear="!filter.application"
                            :selectClass="'input-blue'"
                        ></application-form-select>
                    </b-col>
                </b-row>
            </template>
            <template #search>
                <b-form-input
                    v-model="filter.search"
                    class="input-search"
                    placeholder="Nazwa, opis"
                    @keyup.enter="refreshData()"
                ></b-form-input>
            </template>
        </table-grid-filter>
        <div class="table-margin">
            <table-grid :data="dataList">
                <template #actions="{ item }">
                    <RoleActions :item="item.item" @handleAction="handleAction"></RoleActions>
                </template>
            </table-grid>
        </div>
        <RoleModal
            :itemData="itemData"
            :applications="applications"
            :permissions="permissions"
            @dataChanged="refreshData"
            :modal="modal"
            :endpoint="endpoint"
        />
    </div>
</template>

<script>
import RoleModal from "../modals/RoleModal.vue";
import RoleActions from "../RoleActions.vue";

export default {
    name: "RoleTab",
    props: {
        users_roles: {type: Array},
        applications: {type: Object, default: {}},
        permissions: {type: Object|Array, default: {}},
        refresh: {type: Boolean, default: false}
    },
    components:{
        RoleModal, RoleActions
    },
    data(){
        return {
            dataList: [],
            itemData: {},
            filter: {
                application: null,
                search: null,
            },
            endpoint: 'api.admin.role.',
            modal: 'modal-role'
        }
    },
    methods: {
        cloneItem(item) {
            this.itemData = Object.assign({},item);
            this.itemData.id = null;
            this.$bvModal.show(this.modal);
        },
        destroyItem(item){
            this.onDestroyItem(this.endpoint, {role: item.id});
        },
        editItem(item){
            this.itemData = item;
            this.$bvModal.show(this.modal);
        },
        handleAction(event) {
            this[event.action](event.item)
        },
        applicationChange(event) {
            this.filter.application = event;
        },
    },
    mounted(){
        this.refreshData();
    },
    watch:{
        refresh: function(){
            this.refreshData()
        }
    }

}
</script>

<style lang="scss" scoped>

</style>
