<template>
    <div class="col-md-4 mx-auto m-4">
        <b-form @submit="onSubmit">
            <b-form-group label="Login" label-for="login">
                <b-form-select
                    v-model="id"
                    required
                >
                    <option value="" disabled>Wybierz użytkownika</option>
                    <option v-for="option in users" :value="option.id">
                        {{ option.first_name }} {{ option.last_name }}
                    </option>
                </b-form-select>
            </b-form-group>
            <b-form-group label="Hasło" label-for="password">
                <b-form-input id="password" v-model="password" placeholder="Wpisz hasło" type="password"></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="danger">Zaloguj</b-button>
        </b-form>
    </div>
</template>

<script>
export default {
    props:{
        users: {type: Array}
    },
    data() {
        return {
            id: '',
            password: ''
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            axios.post('/login', this.payload)
                .then(response => {
                    window.location = '/';
                });
        },
    },
    computed:{
        payload(){
            return {
                id: this.id,
                password: this.password
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
