<template>
    <div class="mt-4">
        <b-card-group>
            <b-card
                title="System SOWA 2.0"
                :img-src="contractsLogo"
                img-alt="SOWA 2.0"
                img-top
                @click="redirect(contractsUrl)"
            />
        </b-card-group>
    </div>
</template>

<script>
export default {
    props:{
        contractsLogo: {type: String},
        contractsUrl: {type: String}
    },
    methods: {
        redirect(url) {
            window.location.href = url;
        }
    }
}
</script>

<style lang="scss" scoped>
*::v-deep .card {
    max-width: calc(33% - 30px);
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
}
*::v-deep .card .card-img-top {
    width: 50%;
}
*::v-deep .card .card-body {
    padding: 0;
}
*::v-deep .card .card-body h4 {
    font-size: 28px;
    margin-top: 12px;
    font-weight: 700;
    margin-bottom: 0;
    color: #002F6D;
}
</style>
