import Vue from "vue";
Vue.component('login', require('./Login.vue').default);
Vue.component('welcome-page', require('./Welcome.vue').default);
Vue.component('applications', require('./Applications.vue').default);
Vue.component('user', require('./Admin/User.vue').default);
Vue.component('table-grid', require('./global/TableGrid.vue').default);
Vue.component('table-grid-filter', require('./global/TableGridFilter.vue').default);
Vue.component('tag-select', require('./global/TagSelect.vue').default);
Vue.component('role-select', require('./global/RoleSelect.vue').default);
Vue.component('application-form-select', require('./global/ApplicationFormSelect.vue').default);
