<template>
    <div class="mr-2">
        <b-form-select
            v-model="selected"
            :select-size="size"
            :required="required"
            :class="selectClass"
        >
            <template #first>
                <b-form-select-option :value="null" disabled>Aplikacja</b-form-select-option>
            </template>
            <option :selected="selected === option.slug" v-for="option in applications" :value="option.slug">
                {{ option.name }}
            </option>
        </b-form-select>
    </div>
</template>

<script>
export default {
    name: "ApplicationFormSelect.vue",
    props: {
        applications: {type: Object, default: {}},
        selectedItem: {default: null},
        required: {type: Boolean, default: false},
        multiple: {type: Boolean, default: false},
        size: {type: Number, default: 1},
        placeholder: {type: String, default: ''},
        clear: {type: Boolean, default:false},
        selectClass: {type: String, default: ''}
    },
    data() {
        return {
            display: 'none',
            selected: this.selectedItem,
            statuses: [],
        }
    },
    watch: {
        selected() {
            this.$emit('applicationChange', this.selected);
        },
        clear() {
            if (this.clear){
                this.selected = null
            }
        }
    }
}
</script>

