<template>
    <div class="col-md-4 mx-auto text-center">
        <b-card
            title="Witaj w systemie SOWA 2.0"
            :img-src="tebLogo"
            img-alt="Sowa 2.0"
            img-top
            class="custom-image"
        >
            <b-card-text>
                W celu zalogowania się do systemu obiegu dokumentów i zarządzania umowami UoP i UCP skorzystaj ze swojego konta Office.
            </b-card-text>
            <b-card-text>
                W przypadku braku konta Office 365 skontaktuj się z administratorem systemu pod adresem {{adminEmail}}
            </b-card-text>

            <b-button class="btn btn-blue btn-secondary btn-md" @click="employeeLogin">
                Logowanie pracownika
            </b-button>
            <b-button class="btn btn-blue btn-secondary btn-md" @click="teacherLogin">
                Logowanie nauczyciela
            </b-button>
        </b-card>
    </div>
</template>

<script>
export default {
    props:{
        tebLogo: {type: String},
        adminEmail: {type: String}
    },
    methods: {
        employeeLogin() {
            window.location.href = route('loginEmployee');
        },
        teacherLogin() {
            window.location.href = route('loginTeacher');
        }
    }
}
</script>

<style lang="scss" scoped>
*::v-deep .card {
    padding: 25px;
}
*::v-deep .card .card-img-top {
    width: 50%;
}
*::v-deep .card .card-body {
    padding: 30px 0 0;
}
*::v-deep .card .card-body .card-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1.5rem;
}
*::v-deep .card .card-body .card-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    width: 85%;
}
*::v-deep .card .card-body button {
    width: 100%;
    margin-top: 15px;
}

.custom-image .card-img-top {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>
