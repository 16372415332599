<template>
    <div>
        <b-modal :id="modal" hide-footer title="Użytkownik" size="xl">
            <b-form @submit="onSubmit">
                <b-row>
                    <b-col>
                        <b-form-group
                            id="input-group-id"
                            label="Id"
                            label-for="id"
                            v-if="user.id"
                        >
                            <b-form-input
                                id="id"
                                v-model="user.id"
                                type="number"
                                disabled
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-name"
                                    label="Imię"
                                    label-for="name">
                            <b-form-input
                                id="name"
                                v-model="user.first_name"
                                type="text"
                                disabled
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-name"
                                    label="Nazwisko"
                                    label-for="name">
                            <b-form-input
                                id="name"
                                v-model="user.last_name"
                                type="text"
                                disabled
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-group-email"
                            label="e-mail"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="user.email"
                                type="text"
                                placeholder="Podaj e-mail"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Role" label-for="role-select">
                            <role-select
                                id="role-select"
                                placeholder="Wybierz role"
                                :items="roles"
                                @itemAdd="addRole"
                                @itemRemove="removeRole"
                                :selected-items="user.roles"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Dostęp do oddziałów:" label-for="department-select">
                            <b-form-checkbox-group
                                v-model="user.departments"
                                id="department-select"
                                style="column-count: 5;"
                                stacked
                                >
                                    <b-form-checkbox
                                        v-for="(department, index) in departments"
                                        :value="department.id"
                                        :key="index"
                                    >
                                        {{department.code}}
                                    </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="modal-footer">
                    <b-button v-if="user.id" class="" @click="setDirector">Mianuj dyrektorem</b-button>
                    <b-button class="" @click="close">Anuluj</b-button>
                    <b-button type="submit" variant="danger">Zapisz</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>
<script>

export default {
    name: "UserModal",
    props: {
        itemData: {type: Object, default: ()=>({})},
        endpoint: {type: String, default: ''},
        modal: {type: String, default: ''},
        roles: {type: Array, default: []}
    },

    data() {
        return {
            user: {},
            selectedRoles: {},
            departments: []
        }
    },
    methods: {
        close() {
            this.clearData();
            this.$bvModal.hide(this.modal)
        },
        onSubmit(event) {
            event.preventDefault();
            this.onModalSubmit(this.newItem, this.endpoint, {user: this.user.id}, this.payload);
        },
        setRoles(roles) {
            this.selectedRoles = Object.assign([], roles);
        },
        addRole(role) {
            this.selectedRoles.push(role);
        },
        removeRole(role) {
            this.selectedRoles = this.selectedRoles.filter(r => {
                return r.id != role.id
            });
        },
        clearData() {
            this.setRoles([]);
            this.user = {};
        },
        setDirector(event){
            event.preventDefault();
            axios.post(route(this.endpoint + 'setdirector', this.user.id), {departments: this.user.departments})
                .then((response) => {
                    this.makeToast(response.data.message, "success");
                })
                .catch((error) => {
                    this.handleError(error);
                });
        }
    },
    computed: {
        newItem() {
            return !!!this.itemData.id;
        },
        payload() {
            return {
                email: this.user.email,
                roles: this.selectedRoles.map(r => {
                    return r.id
                }),
                departments: this.user.departments
            }
        }
    },
    watch: {
        itemData: function (newValue, oldValue) {
            if (!this.isObjectEmpty(this.itemData)) {
                this.user = Object.assign({}, this.itemData);
                this.setRoles(this.user.roles);
            }
        }
    },
    mounted() {
        axios.get(route('api.departments.index'))
            .then(response => {
                this.departments = response.data;
            })
            .catch(error => {
                this.handleError(error);
            });
    },
}
</script>

<style lang="">

</style>
