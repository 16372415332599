<template>
    <div>
        <b-row class="m-0">
            <b-table hover
                     id="my-table"
                     :items="items"
                     :fields="fields"
                     :busy="isBusy"
                     responsive
                     show-empty
            >
                <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
                    <slot :name="slotName" v-bind="scope"/>
                </template>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                </template>
                <template #empty>
                    <h4 class="text-center">Brak danych</h4>
                </template>
                <template #cell(show_details)="row" v-if="detailed" class="text-left">
                    <i :class="['text-center','bi', row.detailsShowing ? 'bi-chevron-up' : 'bi-chevron-down']" @click="row.toggleDetails"></i>
                </template>
                <template #cell(actions)="row">
                    <div class="text-right mr-3">
                        <slot name="actions" :item="row"></slot>
                    </div>
                </template>
            </b-table>
        </b-row>
        <b-row v-if="rows" class="m-0">
            <b-col cols="2" class="pl-0"><p class="pagination-text">Strona: {{ currentPage }} z {{lastPage}}</p></b-col>
            <b-col></b-col>
            <b-col cols="4" class="text-right pagination-text pr-0">
                <span >Liczba wyników na stronie</span>
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    @change="pageChange(currentPage)"
                    size="sm"
                    style="width: 13%"
                ></b-form-select>
                <span >Wyniki {{ from }} - {{ to }} z {{ rows }}</span>
                <b-button-group size="sm">
                    <b-button class="bi bi-chevron-left" variant="light" @click="pageChange(currentPage - 1)" :disabled="perviousDisabled"></b-button>
                    <b-button class="bi bi-chevron-right" variant="light" @click="pageChange(currentPage + 1)" :disabled="nextDisabled"></b-button>
                </b-button-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props:{
        data:[],
        detailed: {type: Boolean, default: false}
    },
    data() {
        return {
            items: [],
            perPage: 10,
            currentPage: 1,
            rows: 0,
            lastPage: 1,
            from: 1,
            to: 1,
            fields: null,
            pageOptions: [5, 10, 15, 25, 50, 100],
        }
    },
    mounted() {
        this.prepareData();
    },
    methods: {
        prepareData(){
            if(this.data.data){
                this.items = this.data.data
                this.perPage = this.data.meta.per_page
                this.currentPage = this.data.meta.current_page
                this.rows = this.data.meta.total
                this.lastPage = this.data.meta.last_page
                this.from = this.data.meta.from
                this.to = this.data.meta.to
                this.fields = this.data.columns
            }
        },
        pageChange(page){
            this.$parent.refreshData(page, this.perPage);
        }
    },
    computed:{
        nextDisabled(){
            return this.currentPage + 1 > this.lastPage;
        },
        perviousDisabled(){
            return this.currentPage - 1 < 1;
        }
    },
    watch: {
        data(){
            this.prepareData()
        }
    }
}
</script>
<style>
.pagination-text{
    color: #5D686E;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word
}
</style>
