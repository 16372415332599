export default {
    data() {
        return {
            isBusy: false
        };
    },
    methods: {
        makeToast(content, variant = null, title = null, placement = 'b-toaster-top-center') {
            this.$bvToast.toast(content, {
                title: title,
                variant: variant,
                toaster: placement,
                autoHideDelay: 5000,
                appendToast: true
            })
        },
        isObjectEmpty(object) {
            return !(Object.keys(object).length)
        },
        can(permission) {
            if (this.isObjectEmpty(this.$store.state.authorization)) {
                return false;
            }
            let permissions = this.$store.state.authorization.permissions;
            return this.authorize(permission, permissions);
        },
        hasRole(role) {
            if (this.isObjectEmpty(this.$store.state.authorization)) {
                return false;
            }
            let roles = this.$store.state.authorization.roles;
            return this.authorize(role, roles);
        },
        authorize(element, authorizeArray) {
            if (this.$store.state.authorization.admin) {
                return true;
            } else if (Array.isArray(element)) {
                for (const el of element) {
                    if (authorizeArray.includes(el)) {
                        return true;
                        break;
                    }
                }
                return false;
            } else {
                return authorizeArray.includes(element);
            }
            return false;
        },
        handleResponse(response, data = null) {
            this.makeToast(response.data.message, "success");
            this.$emit("dataChanged", data);
            this.close();
        },
        handleError(error) {
            if (error.response) {
                this.errors = error.response.data;
                this.makeToast(this.errors.message, "danger");
            }
        },
        onModalSubmit(newItem, endpoint, propertyObject, payload) {
            if (newItem) {
                axios.post(route(endpoint + 'store'), payload)
                    .then((response) => {
                        this.handleResponse(response);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });

            } else {
                axios.patch(route(endpoint + 'update', propertyObject), payload)
                    .then((response) => {
                        this.handleResponse(response);
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },
        onDestroyItem(endpoint, itemObject) {
            axios.delete(route(endpoint + 'destroy', itemObject))
                .then((response) => {
                    this.makeToast(response.data.message, 'success');
                    this.refreshData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        onRefreshData(endpoint, filter = null, page = null, perpage = null){
            this.isBusy = true
            axios.get(route(endpoint + 'index', {filters: filter, page: page, perpage: perpage}))
                .then((response) => {
                    this.dataList = response.data
                    this.isBusy = false
                })
                .catch((error) => {
                    this.isBusy = false
                    this.handleError(error);
                });
        },
        refreshData(page = null, perpage = null){
            this.onRefreshData(this.endpoint, this.filter, page, perpage);
        },
        clearFilter(defaults = []){
            Object.entries(this.filter).forEach(entry => {
                if(!defaults.includes(entry[0])){
                    this.filter[entry[0]] = null;
                }
            })
            this.refreshData();
        }
    }
}
