<template>
    <div>
        <b-dropdown variant="link" toggle-class="text-decoration-none p-0 m-0 text-dark" no-caret data-toggle="tooltip">
            <template #button-content>
                <i class="bi bi-three-dots-vertical"></i>
            </template>
            <b-dropdown-item @click="editItem(item)">
                Edytuj
            </b-dropdown-item>
            <b-dropdown-item @click="blockItem(item)">
                Zablokuj użytkownika
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>
<script>
export default {
    name: "UserActions",
    props: {
        item: {type: Object, default: {}}
    },
    methods: {
        refreshData() {
            this.onRefreshData(this.endpoint, this.filter);
        },
        emitRefreshDataEvent() {
            this.$emit('refreshData', this.tags);
        },
        editItem(item) {
            this.$emit('handleAction', {
                action: 'editItem',
                item:  item
            });
        },
        blockItem(item) {
            axios.post(route(this.endpoint + 'block', item.id))
                .then((response) => {
                    this.makeToast(response.data.message, "success");
                    this.emitRefreshDataEvent()
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        destroyItem(item) {
            this.onDestroyItem(this.endpoint, {user: item.id});
        },
    }
}
</script>
