<template>
    <b-dropdown variant="link" toggle-class="text-decoration-none p-0 m-0 text-dark" no-caret
                data-toggle="tooltip" title="Akcja">
        <template #button-content>
            <i class="bi bi-three-dots-vertical"></i>
        </template>
        <b-dropdown-item @click="editItem(item)">
            Edycja
        </b-dropdown-item>
        <b-dropdown-item @click="destroyItem(item)">
            Usuń
        </b-dropdown-item>
    </b-dropdown>
</template>
<script>
export default {
    name: "PermissionActions",
    props: {
        item: {type: Object, default: {}}
    },
    methods: {
        editItem(item) {
            this.$emit('handleAction', {
                action: 'editItem',
                item:  item
            });
        },
        destroyItem(item){
            this.$emit('handleAction', {
                action: 'destroyItem',
                item:  item
            });
        },
    }
}
</script>
