import './bootstrap';
import Vue from "vue";
import BootstrapVue from 'bootstrap-vue';
import { ZiggyVue, Ziggy } from 'ziggy';

import 'bootstrap/dist/css/bootstrap.css'

import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.use(BootstrapVue);
Vue.use(ZiggyVue, Ziggy);
Vue.mixin(require('./mixins').default);
require('./components');

const appMain = new Vue({
    el: '#app',
});
