<template>
    <div
        :id="id"
        size="lg"
        class="mb-2"
    >
        <b-form-select
            v-model="selected"
            @change="itemSelect"
            :disabled="disabled"
            :options="availableItems"
            :textField="textField"
            :valueField="valueField"
            class="mb-2"
        >
            <template #first>
                <b-form-select-option disabled :value="null">{{ placeholder }}</b-form-select-option>
            </template>
        </b-form-select>
        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2" style="columns: 3;">
            <li>
                <b-form-checkbox
                    v-for="tag in tags"
                    v-model="tag[valueField]"
                    :title="tag[textField]"
                    :key="tag[valueField]"
                    :value="tag[valueField]"
                    @input="removeTag(tag)"
                    checked="checked"
                >{{ tag[textField] }}
                </b-form-checkbox>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: "TagSelect",
    props: {
        id: {type: String, default: 'tags-component-select'},
        placeholder: {type: String, default: ''},
        items: [],
        selectedItems: [],
        taggabled: {type: Boolean, default: false},
        textField: {type: String, default: 'name'},
        valueField: {type: String, default: 'id'}
    },
    data() {
        return {
            selected: this.taggabled ? [] : this.selectedItem,
            tags: this.selectedItems ?? [],

        }
    },
    methods: {
        itemSelect(id) {
            const item = this.availableItems.find((i) => i.id === id);
            if (item) {
                this.tags.push(item);
            }
            this.$emit('itemChange', this.tags);
            this.$emit('itemAdd', item);
        },
        removeTag(tag) {
            this.tags = this.tags.filter((t) => t.id !== tag.id);
            this.clearSelected();
            this.$emit('itemChange', this.tags);
            this.$emit('itemRemove', tag);
        },
        clearSelected() {
            this.selected = null
        },
    },
    computed: {
        availableItems() {
            return this.items.filter(available => !this.tags.some(tag => available.id === tag.id));
        },
        disabled() {
            return this.availableItems.length === 0;
        }
    },
    watch: {
        items() {
            this.tags = [];
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
