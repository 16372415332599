<template>
    <div>
        <b-modal id="modal-permission" hide-footer title="Uprawnienie">
            <b-form @submit="onSubmit">
                <b-form-group
                    id="input-group-id"
                    label="Id uprawnienia"
                    label-for="id"
                    v-if="permission.id"
                >
                    <b-form-input
                        id="id"
                        v-model="permission.id"
                        type="number"
                        disabled
                    ></b-form-input>

                </b-form-group>
                <b-form-group id="input-group-name"
                              label="Nazwa"
                              label-for="name">
                    <b-form-input
                        id="name"
                        v-model="permission.name"
                        type="text"
                        placeholder="Podaj nazwę"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-description"
                              label="Opis"
                              label-for="description">
                    <b-form-input
                        id="description"
                        v-model="permission.description"
                        type="text"
                        placeholder="Podaj opis"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-module"
                              label="Aplikacja"
                              label-for="application">
                    <application-form-select
                        :selectedItem="permission.application_slug"
                        @applicationChange="applicationChange"
                        :applications="applications"
                    />
                </b-form-group>

                <div class="modal-footer">
                    <b-button class="" @click="close">Anuluj</b-button>
                    <b-button type="submit" variant="danger">Zapisz</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>

</template>

<script>
export default {
    name: "PermissionModal",
    props: {
        itemData: {},
        endpoint: {type: String, default: ''},
        modal: {type: String, default: ''},
        applications: {type: Object, default: {}}
    },
    data() {
        return {
            permission: {}
        }
    },
    methods: {
        close() {
            this.$bvModal.hide(this.modal)
        },
        onSubmit(event) {
            event.preventDefault();
            this.onModalSubmit(this.newItem, this.endpoint, {permission: this.permission.id}, this.permission);
        },
        applicationChange(event) {
            this.permission.application = event;
        }
    },
    computed: {
        newItem() {
            return !!!this.itemData.id;
        }
    },
    watch: {
        itemData: function (newValue, oldValue) {
            this.permission = Object.assign({}, this.itemData);
        }
    }
}
</script>
