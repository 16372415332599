<template>
    <div>
        <table-grid-filter @applayFilter="refreshData()" @clearFilter="clearFilter()" :with-search="true">
            <template #data>
                <b-row>
                    <b-col>
                        <application-form-select
                            :applications="applications"
                            @applicationChange="applicationChange"
                            :clear="!filter.application"
                            :selectClass="'input-blue'"
                        ></application-form-select>
                    </b-col>
                </b-row>
            </template>
            <template #search>
                <b-form-input v-model="filter.search" class="input-search" placeholder="Nazwa, opis" @keyup.enter="refreshData()"></b-form-input>
            </template>
        </table-grid-filter>
        <div class="table-margin">
            <table-grid :data="dataList">
                <template #actions="{ item }">
                    <PermissionActions :item="item.item" @handleAction="handleAction"></PermissionActions>
                </template>
            </table-grid>
        </div>
        <PermissionModal
            :itemData="itemData"
            @dataChanged="refreshData"
            :modal="modal"
            :endpoint="endpoint"
            :applications="applications"
        />
    </div>
</template>

<script>
import PermissionModal from "../modals/PermissionModal.vue";
import PermissionActions from "../PermissionActions.vue";

export default {
    name: "PermissionTab",
    props: {
        applications: {type: Object},
        refresh: {type: Boolean, default: false}
    },
    components:{
        PermissionModal, PermissionActions
    },
    data(){
        return {
            dataList: [],
            itemData: {},
            filter: {
                application: null,
                search: null
            },
            endpoint: 'api.admin.permission.',
            modal: 'modal-permission'
        }
    },
    methods: {
        destroyItem(item){
            this.onDestroyItem(this.endpoint, {permission: item.id});
        },
        editItem(item){
            this.itemData = item;
            this.$bvModal.show(this.modal);
        },
        handleAction(event) {
            this[event.action](event.item)
        },
        applicationChange(event) {
            this.filter.application = event;
        }
    },
    mounted() {
        this.refreshData();
    },
    watch: {
        refresh: function(){
            this.refreshData()
        }
    }

}
</script>

<style lang="scss" scoped>

</style>


